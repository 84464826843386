import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
import AirCutsIntro from "./Sections/AirCutsIntro.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function StartPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>


      <Parallax filter image={require("assets/img/airport-2373727.jpg")}>
        <div className={classes.containerCenter}>


              <h1 className={classes.title}>Choose your journey</h1>
              <br />
              <Button
                color="info"
                size="lg"
                href="/login"
                rel="noopener noreferrer"
                round
              >
                <i className="fas fa-user" />
                User
              </Button>

              <Button
                color="info"
                size="lg"
                href="/login"
                rel="noopener noreferrer"
                round
              >
                <i className="fas fa-cut" />
                Stylist
              </Button>



        </div>
      </Parallax>

      <Footer />
    </div>
  );
}
