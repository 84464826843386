import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import Header from "components/Header/Header.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksDisabled from "components/Header/HeaderLinksDisabled.js";

import AirLand from "views/AirLand/AirLand.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import NewHome from "views/NewHome/NewHome.js";
import StartPage from "views/Onboarding/StartPage.js";

// import AirCutsHome from "views/AirCutsHome/AirCutsHome.js";
import Components from "views/Components/Components.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import MoodsProfile from "views/ProfilePage/MoodsProfile.js";
import ACLogin from "views/LoginPage/ACLogin.js";
import ACRegister from "views/LoginPage/ACRegister.js";

import { auth, createUserProfileDocument } from "components/Firebase/fire.js";
import { setCurrentUser } from "./redux/user/user-actions";

class App extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  unsubcribeFromAuth = null;

  componentDidMount() {
    const { setCurrentUser } = this.props;

    this.unsubcribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot((snapShot) => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          });
        });
      }

      setCurrentUser(userAuth);
    });
  }

  componentWillUnmount() {
    this.unsubcribeFromAuth();
  }

  render() {
    return (
      <div>
        <Header
          color="transparent"
          //  routes={dashboardRoutes}
          //  brand="AirCuts"
          rightLinks={<HeaderLinksDisabled />}
          fixed
          changeColorOnScroll={{
            height: 85,
            color: "dark",
          }}
        />
        <Switch>
          <Route exact path="/" component={NewHome} />
          <Route exact path="/home" component={LandingPage} />
          <Route exact path="/start" component={StartPage} />
          <Route path="/firebase" component={AirLand} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/home" component={Components} />
          <Route path="/moods" component={MoodsProfile} />
          <Route
            exact
            path="/login"
            render={() =>
              this.props.currentUser ? <Redirect to="/home" /> : <ACLogin />
            }
          />
          <Route path="/register" component={ACRegister} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
