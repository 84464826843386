import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import TouchApp from "@material-ui/icons/TouchApp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function AirCutsIntro() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Is AirCuts a cut above the rest?</h2>
          <h5 className={classes.description}>
            Our mission is to help make the world look and feel great through a simple haircut. At AirCuts we are developing revolutionary technology to help Stylists and Mobile Hairdressers become more accessible, safer and reliable.






             is designed for people who want to remove the frustration of not being able to find a stylist who is available at a time that suits them, and for stylist’s who want to remove all the unnecessary planning, promotion and scheduling of appointments and focus on what they are best at – <strong> making people look great and feel great </strong>.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fast Bookings"
              description="Including Repeat Appointments & Scheduling done whenever you want, from the comfort of your own phone."
              icon={TouchApp}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Trusted"
              description="To keep everyone safe and secure, we provide a clear and transparent rating system that lets you know exactly who you are trusting with your hair. You can see their previous work, and make sure they are the right stylist for you."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Instant Appointment Updates"
              description="Running late? Last minute change of plan? No problem, we try to make the booking process as easy as possible, and when the worst should happen – you can cancel or amend your appointments instantly."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
