// import firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';



var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: "AIzaSyDVWDwrVfkYq2bq148vwq7XTsifT8Uecs4",
  authDomain: "aircuts-eae49.firebaseapp.com",
  databaseURL: "https://aircuts-eae49.firebaseio.com",
  projectId: "aircuts-eae49",
  storageBucket: "aircuts-eae49.appspot.com",
  messagingSenderId: "355629363655",
  appId: "1:355629363655:web:0cc816009087fbef"
};

export const createUserProfileDocument = async(userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if(!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      })

    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

return userRef;
}

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

// var Fire = firebase.initializeApp(config);
// export default Fire;

export default firebase;
